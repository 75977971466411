import React, { useState } from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { Link } from 'react-router-dom'
import msg from '../../assets/images/msg.png'
import pbx from '../../assets/images/pbx.png'
import job from '../../assets/images/job.png'

import yt from '../../assets/images/youtube.png'
import twr from '../../assets/images/twitter.png'
import fb from '../../assets/images/facebook.png'

import Modal from './modal-component/modal'

let fbRedirect = () => {
    window.open('https://www.facebook.com/APMTerminalsQtz/', '_blank')
}

let twrRedirect = () => {
    window.open('https://twitter.com/APMTerminalsQTZ', '_blank')
}

let ytRedirect = () => {
    window.open('https://www.youtube.com/channel/UC_xnhnxJm2WVBW0fWz5_2Eg', '_blank')
}

const Footer = props => {
    const [modal, setModal] = useState()

    const toggle3 = () => {
        setModal(!modal)
    }

    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <Modal toggle={toggle3} modal={modal} />
                <div className='row'>
                    <div className='col-sm-12  col-md-12 col-xl-1' style={{ alignSelf: 'center' }}>
                        <div style={{ textAlign: 'center' }}>
                            <div className='card-body avatar-showcase'>
                                <div className='avatars'>
                                    <div className='avatar '>
                                        <h4>{'Contáctanos'}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12  col-md-4 col-xl-3'>
                        <div style={{ textAlign: 'center' }}>
                            <div className='card-body avatar-showcase'>
                                <div className='avatars'>
                                    <div className='avatar'>
                                        <img id='llamar' className='img-100 rounded-circle cursor' src={msg} alt='#' />
                                        <UncontrolledTooltip placement='top' target='llamar'>
                                            {'Llamar'}
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                                <h3 style={{ fontWeight: 'Bold' }}>{'PBX: '}</h3>
                                <h4>{'(+502) 2379-7272'}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12  col-md-4 col-xl-4'>
                        <div style={{ textAlign: 'center', 'word-break': 'break-word' }}>
                            <div className='card-body avatar-showcase'>
                                <div className='avatars'>
                                    <div className='avatar'>
                                        <img id='email' className='img-100 rounded-circle cursor' src={pbx} alt='#' typeof='email' />
                                        <UncontrolledTooltip placement='top' target='email'>
                                            {'Enviar email'}
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                                <h3 style={{ fontWeight: 'Bold' }}>{'Email: '}</h3>
                                <h4>{'servicioalclientegtm@apmterminals.com'}</h4>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-12  col-md-4 col-xl-3'>
                        <div style={{ textAlign: 'center' }}>
                            <div className='card-body avatar-showcase'>
                                <div className='avatars'>
                                    <div className='avatar'>
                                        <Link to='/empleo-apmtq'>
                                            <img id='empleo' className='img-100 rounded-circle cursor' src={job} alt='#' />
                                        </Link>
                                        <UncontrolledTooltip placement='top' target='empleo'>
                                            {'Llenar formulario'}
                                        </UncontrolledTooltip>
                                    </div>
                                </div>
                                <h3 style={{ fontWeight: 'Bold' }}>{'Empleo'}</h3>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-xl-1' style={{ alignSelf: 'center' }}>
                        <div className='card-body avatar-showcase'>
                            <div className='row col-sm-12' style={{ placeContent: 'center' }}>
                                <div className='avatar row'>
                                    <img className='img-50 cursor m-2' src={yt} alt='#' onClick={() => ytRedirect()} />
                                    <img className='img-50 cursor m-2' src={twr} alt='#' onClick={() => twrRedirect()} />
                                    <img className='img-50 cursor m-2' src={fb} alt='#' onClick={() => fbRedirect()} />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-sm-2' style={{ alignSelf: 'center', writingMode: 'vertical-lr' }}>
                        <div style={{ textAlign: 'center' }}>
                            <div className='card-body avatar-showcase'>
                                <div className='avatars'>
                                    <div className='avatar '>
                                        <div className='col-sm-4 row'>
                                            <img
                                                className='img-50 cursor'
                                                src={yt}
                                                alt='#'
                                                onClick={() => ytRedirect()}
                                            />
                                        </div>
                                        <div className='col-sm-4 row'>
                                            <img
                                                className='img-50 cursor'
                                                src={twr}
                                                alt='#'
                                                onClick={() => twrRedirect()}
                                            />
                                        </div>
                                        <div className='col-sm-4 row'>
                                            <img
                                                className='img-50 cursor'
                                                src={fb}
                                                alt='#'
                                                onClick={() => fbRedirect()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className='col-md-6 footer-copyright'>
                        <p className='mb-0'>APM Terminals Quetzal © Copyright 2024</p>
                    </div>
                    <div className='col-md-6 text-right'>
                        <button className='btn btn-warning text-left' type='button' onClick={toggle3}>
                            Terms & Conditions
                        </button>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
